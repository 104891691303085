import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container } from "reactstrap";

function Navigation() {
    const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
    const [navbarCollapse, setNavbarCollapse] = React.useState(false);

    const toggleNavbarCollapse = () => {
        setNavbarCollapse(!navbarCollapse);
        document.documentElement.classList.toggle("nav-open");
    };

    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (document.documentElement.scrollTop > 299 || document.body.scrollTop > 299) {
                setNavbarColor("");
            } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
                setNavbarColor("navbar-transparent");
            }
        };

        window.addEventListener("scroll", updateNavbarColor);

        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });



    return (
        <Navbar className={classnames("fixed-top", navbarColor)} color-on-scroll="300" expand="lg">
            <Container>
                <div className="navbar-translate">
                    <NavbarBrand 
                        data-placement="bottom" 
                        to="/" 
                        title="XP NRG LLC" 
                        tag={Link}
                        style={{color: "rgba(255, 255, 255)"}}
                    >
                        XP NRG
                    </NavbarBrand>
                    <a 
                        data-placement="bottom" 
                        href="https://voskhod.software"
                        title="Voskhod" 
                        tag={Link}
                        style={{color: "rgba(200, 200, 200)", fontWeight: 500}}
                    >
                        VOSKHOD
                    </a>
                    <button
                        aria-expanded={navbarCollapse}
                        className={classnames("navbar-toggler navbar-toggler", {toggled: navbarCollapse})}
                        onClick={toggleNavbarCollapse}
                    >
                        <span className="navbar-toggler-bar bar1" />
                        <span className="navbar-toggler-bar bar2" />
                        <span className="navbar-toggler-bar bar3" />
                    </button>
                </div>
                <Collapse 
                    className="justify-content-end" 
                    navbar 
                    isOpen={navbarCollapse}
                >
                    <Nav navbar>
                        <NavItem>
                            <NavLink 
                                href="mailto:info@xpnrg.com" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                style={{color: "rgba(255, 255, 255)"}}
                            >
                                <i className="nc-icon nc-email-85" /> Drop us a line
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;
