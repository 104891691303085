import React, { Component } from 'react'
import Navigation from "../../components/Navigation/Navigation";
import BackgroundImage from "../../components/BackgroundImage/BackgroundImage";

export default class IndexPage extends Component {
    render() {
        return (
            <>
                <Navigation />
                <BackgroundImage/>
            </>
        )
    }
}
