import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import "./assets/css/bootstrap.min.css";
import "./assets/scss/xpnrg-kit.scss";
import "./assets/custom/custom.css";

import IndexPage from "./pages/IndexPage/IndexPage";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Switch>
                <Route
                    path="/"
                    render={props => <IndexPage {...props} />}
                />
                <Route 
                    path="*"
                    component={IndexPage} />}
                />
                {/* <Redirect to="/" /> */}
            </Switch>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);
