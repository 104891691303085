import React from "react";
import bgImage from "../../assets/img/bg.jpg";

export default function BackgroundImage() {
    let pageHeader = React.createRef();

    React.useEffect(() => {
        if (window.innerWidth < 991) {
            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform = "translate3d(0," + windowScrollTop + "px,0)";
            };
            window.addEventListener("scroll", updateScroll);

            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };
        }
    });

    return (
        <>
            <div
                style={{ backgroundImage: `url(${bgImage})` }}
                className="page-header"
                data-parallax={false}
                ref={pageHeader}
            >
           
            <p className="category category-absolute">
                Copyright &copy; 2020-2022 XP NRG LLC. All Rights Reserved.
            </p>
            </div>
        </>
    )
}
